<template>
  <el-row type="flex" justify="center">
    <el-col :xs="24" :sm="24" :md="18">
      <el-card>
        <user-edit-self-form></user-edit-self-form>
      </el-card>
    </el-col>
  </el-row>
</template>


<script>
import UserEditSelfForm from "@/components/Forms/UserEditSelfForm"

export default {
  name: "User",

  components: {
    UserEditSelfForm,
  },
}
</script>
