<template>
  <el-form
    @submit.native.prevent="update"
    :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
    label-width="100px"
  >
    <el-row class="user-edit-heading">
      <span>Your Settings</span>
    </el-row>
    <el-row>
      <el-col :md="16">
        <el-form-item
          :error="form.errors.first('name') ? form.errors.first('name') : null"
          label="Name"
          required
        >
          <el-input
            v-model="user.name"
            type="text"
            placeholder="Name"
            @focus="form.errors.clear('name')"
            v-input-focus
          ></el-input>
        </el-form-item>
        <el-form-item
          :error="
            form.errors.first('email') ? form.errors.first('email') : null
          "
          label="Email"
          required
        >
          <el-input
            v-model="user.email"
            type="email"
            placeholder="Email"
            @focus="form.errors.clear('email')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :error="
            form.errors.first('timezone') ? form.errors.first('timezone') : null
          "
          label="Timezone"
          required
        >
          <el-select
            :style="{ width: '100%' }"
            @focus="form.errors.clear('timezone')"
            v-model="user.timezone"
          >
            <el-option
              v-for="item in timezones"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col
        tyle="display: flex; align-items: center; justify-content: center; margin-bottom: 1rem;"
        :md="8"
      >
        <el-upload
          class="avatar-uploader"
          drag
          action="#"
          :http-request="uploadAvatar"
          :show-file-list="false"
          v-loading="loading.avatar"
        >
          <img v-if="user.avatar" :src="user.avatar" class="avatar" />
          <p class="uploader-container-text" v-else>
            <font-awesome-icon
              icon="cloud-upload-alt"
              style="margin-bottom: 0.5rem"
              size="3x"
            ></font-awesome-icon>
            <br />
            Drag or click to upload an avatar.
          </p>
        </el-upload>
      </el-col>
    </el-row>
    <el-row>
      <el-form-item
        :error="
          form.errors.first('password') ? form.errors.first('password') : null
        "
        label="Password"
      >
        <el-col
          :sm="12"
          :style="
            $root.windowWidth > 767
              ? { 'padding-right': '.5rem' }
              : { 'padding-bottom': '.5rem' }
          "
        >
          <el-input
            v-model="user.password"
            type="password"
            placeholder="New Password"
            @focus="form.errors.clear('password')"
          ></el-input>
        </el-col>
        <el-col :sm="12">
          <el-input
            v-model="user.password_confirmation"
            type="password"
            placeholder="Confirm New Password"
            @focus="form.errors.clear('password')"
          ></el-input>
        </el-col>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item
        :error="
          form.errors.first('notifications')
            ? form.errors.first('notifications')
            : null
        "
        label="Notifications"
      >
        <el-radio-group v-model="user.notification_setting" size="medium">
          <el-radio-button label="Email"></el-radio-button>
          <el-radio-button label="Web"></el-radio-button>
          <el-radio-button label="Both"></el-radio-button>
          <el-radio-button label="None"></el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-row>
    <el-row>
      <el-col :md="24" display="flex" align="center">
        <el-button
          type="primary"
          plain
          medium
          native-type="submit"
          v-loading="loading.form"
        >
          Update
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<style lang="scss">
</style>

<script>
import DateTime from "@/mixins/DateTime"

import Form from "form-backend-validation"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "UserEditSelfForm",

  mixins: [DateTime],

  data() {
    return {
      user: {},
      form: new Form(),
      loading: {
        form: false,
        avatar: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth/user",
    }),
    timezones() {
      return this.timeZonesList()
    },
  },

  methods: {
    ...mapActions({
      authUpdate: "auth/update",
    }),
    update() {
      this.loading.form = true

      let formData = {
        name: this.user.name,
        email: this.user.email,
        timezone: this.user.timezone,
        notification_setting: this.user.notification_setting,
        password: this.user.password,
        password_confirmation: this.user.password_confirmation,
      }

      this.axios
        .post("auth/update/", formData)
        .then((response) => {
          this.$auth.fetch({
            success: function () {
              this.authUpdate(this.$auth.user())
              this.$message.success(response.data.message)
              this.loading.form = false
            },
            error: function () {
              this.$root.globalRequestError(response)
              this.loading.form = false
            },
          })
        })
        .catch((resp) => {
          this.loading.form = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
    uploadAvatar(data) {
      this.loading.avatar = true

      let formData = new FormData()
      formData.append("file", data.file)

      this.axios
        .post("auth/avatar/", formData)
        .then((response) => {
          this.$auth.fetch({
            success: function (resp) {
              this.$emit("update:file", data)
              this.authUpdate(this.$auth.user())
              this.user.avatar = resp.data.data.avatar
              this.$message.success(response.data.message)
              this.loading.avatar = false
            },
            error: function (resp) {
              this.$emit("update:file", {})
              this.$root.globalRequestError(resp)
              this.loading.avatar = false
            },
          })
        })
        .catch((resp) => {
          this.loading.avatar = false
          this.$emit("update:file", {})
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },

  created() {
    this.user = this.$_.pick(this.auth, [
      "name",
      "email",
      "timezone",
      "avatar",
      "notification_setting",
    ])
  },
}
</script>
